<template>
  <div>
    <forgot-password-component />
  </div>
</template>

<script>
import ForgotPasswordComponent from "@/components/gate/ForgotPasswordComponent";

export default {
  name: "ForgotPasswordPage",
  components: { ForgotPasswordComponent },
  computed: {},

  created() {}
};
</script>
