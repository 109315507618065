<template>
  <div>
    <v-card tile outlined>
      <v-toolbar color="primary" class="white--text" flat dense>
        <v-toolbar-title text-center>Forgot Password</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert type="error" v-if="error">{{error}}</v-alert>
        <v-alert type="success" dense v-if="success">{{success}}</v-alert>
        <v-form ref="forgotPassword" v-if="!success">
          <v-text-field
            label="Your Registered Email"
            type="email"
            ref="email"
            v-model="email"
            :rules="commonValidationRules.emailIdRules"
            outlined
            dense
            class="mt-4"
          />
          <v-hover v-slot:default="{ hover }">
            <v-btn
              large
              block
              :outlined="hover ? false:true"
              :color="hover ? 'primary' : 'primary'"
              class="body"
              tile
              @click="resetPassword"
              :disabled="loading"
            >Reset Password</v-btn>
          </v-hover>
        </v-form>
        <v-row>
          <v-col class="mt-5 pt-0" cols="12">
            <p>Forgot your registered email?
              <a href="mailto:contact@openstage.live">Contact Us</a>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { commonValidationRules } from "@/services/Utils";
import gateService from '@/services/gate.service';
export default {
  name: "ForgotPasswordComponent",
  data: () => ({
    email: null,
    commonValidationRules,
    error: null,
    success: null,
    loading: false
  }),
  methods: {
    async resetPassword() {
      if (this.$refs.forgotPassword.validate()) {
        this.loading = true
        await gateService.forgotPassword(this.email)
          .then(
            data => {
              this.success = data.message

            },
            error => {
              this.error = error
            }
          )
        this.loading = false
      }
    }
  }
};
</script>
